import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {
  DropdownModule,
  HeaderComponentModule,
  HighlightMatchPipeModule,
  IconComponentModule,
  NewLinePipeModule,
  SideMenuComponentModule,
  SmartTextAreaComponentModule,
} from '@didgigo/lib-angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {LinkyPipe} from 'ngx-linky';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {ApiConnectionsTabComponent} from './admin-tab/api-connections-tab.component';
import {AgenciesTabComponent} from './agencies-tab/agencies-tab.component';
import {AnalyzeEntriesComponent} from './analyze-entries/analyze-entries.component';
import {ApiAnalyzerTabComponent} from './api-analyzer-tab/api-analyzer-tab.component';
import {ApiReferenceAnalyzerModalComponent} from './api-reference-analyzer-modal/api-reference-analyzer-modal.component';
import {ApiReferenceAnalyzerTabComponent} from './api-reference-analyzer/api-reference-analyzer-tab.component';
import {ApiReferenceOverviewComponent} from './api-reference-overview/api-reference-overview.component';
import {AppComponent} from './app.component';
import {BookingAnalyzerMessagesTabComponent} from './booking-analyzer-messages-tab/booking-analyzer-messages-tab.component';
import {BookingAnalyzerModalComponent} from './booking-analyzer-modal/booking-analyzer-modal.component';
import {BookingAnalyzerComponent} from './booking-analyzer/booking-analyzer.component';
import {BookingMessageDropdownModule} from './components/booking-message-dropdown/booking-message-dropdown.module';
import {ProductScoreModule} from './components/product-score/product-score.module';
import {DashboardTabComponent} from './dashboard-tab/dashboard-tab.component';
import {HomePageComponent} from './home-page/home-page.component';
import {JsonTreeViewerComponent} from './json-tree-viewer/json-tree-viewer.component';
import {ListApiAgenciesComponent} from './list-api-agencies/list-api-agencies.component';
import {ListApiConnectionsComponent} from './list-api-connections/list-api-connections.component';
import {ListBookingsComponent} from './list-bookings/list-bookings.component';
import {ListMappingComponent} from './list-mapping/list-mapping.component';
import {ListOnboardingTemplatesComponent} from './list-onboarding-templates/list-onboarding-templates.component';
import {LoginGuard} from './login-guard';
import {LoginPageComponent} from './login-page/login-page.component';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {ManualImportTabComponent} from './manual-import-tab/manual-import-tab.component';
import {MappingTabComponent} from './mapping-tab/mapping-tab.component';
import {AddAgentModalComponent} from './modals/add-agent-modal/add-agent-modal.component';
import {EditAgentModalComponent} from './modals/edit-agent-modal/edit-agent-modal.component';
import {OnboardingTabComponent} from './onboarding-tab/onboarding-tab.component';
import {ProductSearchComponent} from './product-search/product-search.component';
import {ProposalImportEndModalComponent} from './proposal-import-end-modal/proposal-import-end-modal.component';
import {ReportsTabComponent} from './reports-tab/reports-tab.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPanelComponent,
    MappingTabComponent,
    DashboardTabComponent,
    ReportsTabComponent,
    ApiAnalyzerTabComponent,
    ApiConnectionsTabComponent,
    AgenciesTabComponent,
    OnboardingTabComponent,
    ListBookingsComponent,
    ListMappingComponent,
    ListApiConnectionsComponent,
    ListApiAgenciesComponent,
    ProposalImportEndModalComponent,
    ListOnboardingTemplatesComponent,
    ApiReferenceAnalyzerTabComponent,
    ApiReferenceAnalyzerModalComponent,
    BookingAnalyzerComponent,
    BookingAnalyzerModalComponent,
    BookingAnalyzerMessagesTabComponent,
    AnalyzeEntriesComponent,
    LoginPageComponent,
    HomePageComponent,
    JsonTreeViewerComponent,
    ApiReferenceOverviewComponent,
    ManualImportTabComponent,
    ProductSearchComponent,
    AddAgentModalComponent,
    EditAgentModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HeaderComponentModule,
    SideMenuComponentModule,
    ProductScoreModule,
    NgxDatatableModule,
    IconComponentModule,
    HighlightMatchPipeModule,
    NewLinePipeModule,
    NgxJsonViewerModule,
    FormsModule,
    ToastrModule.forRoot(),
    BookingMessageDropdownModule,
    DropdownModule,
    NgxSpinnerModule,
    NgbModule,
    CommonModule,
    RouterModule.forRoot([
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {path: 'home', component: HomePageComponent, pathMatch: 'full', canActivate: [LoginGuard]},
      {path: 'login', component: LoginPageComponent, pathMatch: 'full'},
      {path: '**', redirectTo: '/home'},
    ]),
    SmartTextAreaComponentModule,
  ],
  entryComponents: [
    ApiReferenceAnalyzerModalComponent,
    BookingAnalyzerModalComponent,
  ],
  providers: [
    {provide: 'apiServer', useValue: environment.apiServer},
    {provide: 'apiKey', useValue: environment.apiKey},
    {provide: 'ingestionServer', useValue: environment.ingestionServer},
    {provide: 'applicationInsightsKey', useValue: environment.applicationInsightsKey},
    {provide: LinkyPipe, useClass: LinkyPipe},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    console.log('Using Api Server: ' + environment.apiServer);
    console.log('Using Ingestion Server: ' + environment.ingestionServer);
  }
}
