import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ServiceCodeAnalysis} from '@didgigo/lib-ts';
import {List} from 'immutable';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import {ApiConnectionService} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';

@Component({
  selector: 'app-api-analyzer-tab',
  templateUrl: './api-analyzer-tab.component.html',
  styleUrls: ['./api-analyzer-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiAnalyzerTabComponent implements OnInit {

  constructor(
    readonly apiConnection: ApiConnectionService,
    readonly toastr: ToastrService,
    readonly excel: ExcelCreatorService,
  ) {
    this.rows = this.getRows();
  }

  locations: ReplaySubject<object> = new ReplaySubject(1);
  settings: ReplaySubject<object> = new ReplaySubject(1);

  rowFilter: BehaviorSubject<string> = new BehaviorSubject('');

  rows: Observable<readonly object[]>;

  private filterServiceCodeAnalysis(x: ServiceCodeAnalysis, val: string): boolean {
    return x.type.exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.description.exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.productType.exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.entryType.exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.transportType.exists(s => s.toLowerCase().includes(val.toLowerCase()));
  }

  private filterServiceCodeAnalysisList(serviceCodes: List<ServiceCodeAnalysis>, val: string): object[] {
    return serviceCodes
      .filter(x => this.filterServiceCodeAnalysis(x, val))
      .map(analysis => ({
        type: analysis.type.getOrElse(''),
        description: analysis.description.getOrElse(''),
        product_type: analysis.productType.getOrElse(''),
        entry_type: analysis.entryType.getOrElse(''),
        transport_type: analysis.transportType.getOrElse(''),
      })).toArray();
  }

  private getRows(): Observable<object[]> {
    return combineLatest(this.apiConnection.listServicesForSelectedAgency(), this.rowFilter)
      .pipe(debounceTime(300))
      .pipe(filter(x => x.length > 1))
      .pipe(map(([mappings, val]) => this.filterServiceCodeAnalysisList(mappings, val)));
  }

  ngOnInit(): void {
    this.apiConnection.listLocationsForSelectedAgency().then(x => this.locations.next(x.toJSON()));
    this.apiConnection.getSystemSettingsXmlForSelectedAgencyAsJson().then(x => this.settings.next(x));
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase().trim());
  }
}
