import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {EitherUtils, Product, ProductJsonSerializer, PromiseUtils} from '@didgigo/lib-ts';
import {Either, None, Option, Some} from 'funfix-core';
import {ReplaySubject} from 'rxjs';
import {ApiConnectionService} from '../services/api-connection.service';

export class ApiReferenceAnalysisData {
  constructor(
    readonly optionXml: Option<object> = None,
    readonly supplierXml: Option<object> = None,
    readonly savedXml: Option<object> = None,
    readonly mappedProduct: Option<Product> = None,
    readonly importableProduct: Option<Product> = None,
    readonly dpXml: Option<object> = None,
  ) {
  }

  getImportableProductJson(): Option<object> {
    return this.importableProduct.map(x => ProductJsonSerializer.instance.toJson(x));
  }

  getMappedProductJson(): Option<object> {
    return this.mappedProduct.map(x => ProductJsonSerializer.instance.toJson(x));
  }
}

@Component({
  selector: 'app-api-reference-analyzer',
  templateUrl: './api-reference-analyzer.component.html',
  styleUrls: ['./api-reference-analyzer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiReferenceAnalyzerTabComponent implements OnInit {

  constructor(
    readonly connection: ApiConnectionService,
    readonly api: DidgigoApiService,
  ) {
  }

  @Input()
  apiReference: string;

  data: ReplaySubject<ApiReferenceAnalysisData> = new ReplaySubject(1);

  @Input()
  productId: number | null;

  getFallbackXml(cid: Either<string, number>): Promise<Either<string, object>> {
    return PromiseUtils.sequenceEitherPromise(cid.map(id =>
      this.api.getFallbackXmlForApiProductRef(this.apiReference, id)
        .then(x => x.map(v => this.connection.convertXmlDocumentToJson(v)))));
  }

  getMappedProduct(): Promise<Either<string, Product>> {
    return PromiseUtils.sequenceEitherPromise(EitherUtils.liftEither(this.productId, 'No mapped product').map(id => this.api.getProductById(id)));
  }

  async ngOnInit(): Promise<void> {
    const cid = EitherUtils.toEither(this.connection.getCurrentUsingAsCompanyId(), 'Missing using as company id');
    const productXml = await this.connection.getProductApiReferenceXmlForSelectedAgencyAsJson(this.apiReference);
    const supplierXml = await this.connection.getSupplierInfoXmlForSelectedAgencyAsJson(this.apiReference);
    const fallbackXml = await this.getFallbackXml(cid);
    const didgigoProduct = await this.getMappedProduct();
    const importableProduct = await this.connection.getProductApiReferenceAsProductForSelectedAgency(this.apiReference);
    const dpXml = await this.connection.isCCRS() ? this.connection.getDpProductApiReferenceXmlForSelectedAgencyAsJson(this.apiReference) : Promise.resolve({});
    this.data.next(new ApiReferenceAnalysisData(
      Some(productXml),
      Some(supplierXml),
      fallbackXml.toOption(),
      didgigoProduct.toOption(),
      Some(importableProduct),
      Some(dpXml)));
  }
}
