import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-proposal-import-end-modal',
  templateUrl: './proposal-import-end-modal.component.html',
  styleUrls: ['./proposal-import-end-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalImportEndModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
  ) {
  }

  @Input()
  proposalId: number;

  qsid: string;

  @Input()
  reference: string;

  // This was added as a quickfix for the security / GDPR / privacy issue that was reported relating to ePresentations in April 2023
  // It is not meant to be permanent
  getQsidForEpresentations(qsid: string): string {
    const proposalId = atob(qsid).substr(2);
    return btoa(`1234567890123456${proposalId}1234567890123456`).replace('=', '');
  }

  ngOnInit(): void {
    this.qsid = btoa(`PR${this.proposalId}`);
  }

}
