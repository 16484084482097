import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {ApiAgency, ApiAgencyJsonSerializer} from '@didgigo/lib-ts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Option} from 'funfix-core';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay} from 'rxjs/operators';
import {AddAgentModalComponent} from '../modals/add-agent-modal/add-agent-modal.component';
import {EditAgentModalComponent} from '../modals/edit-agent-modal/edit-agent-modal.component';
import {ApiConnectionService} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';
import {ToastHandlerService} from '../services/toast-handler-service';

@Component({
  selector: 'app-list-api-agencies',
  templateUrl: './list-api-agencies.component.html',
  styleUrls: ['./list-api-agencies.component.scss'],
})
export class ListApiAgenciesComponent implements OnInit {

  constructor(
    readonly apiConnection: ApiConnectionService,
    readonly api: DidgigoApiService,
    readonly excel: ExcelCreatorService,
    readonly modalService: NgbModal,
    readonly cd: ChangeDetectorRef,
    readonly toast: ToastHandlerService,
  ) {
    this.rows = this.getRows();
  }

  rowFilter: BehaviorSubject<string> = new BehaviorSubject('');

  rows: Observable<readonly object[]>;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  async deleteAgent(agencyId: number): Promise<void> {
    const request = await this.apiConnection.didgigoService.deleteApiCompanyAgentMapping(agencyId);
    if (request.isLeft()) {
      this.toast.showError('Error deleting company', request.value);
      return;
    }
    this.toast.showSuccess('Company deleted', `Successfully deleted agent`);
    this.apiConnection.agencies.next(this.removeAgencyFromSubject(this.getAgencyIndex(agencyId)));
  }

  private filterAgencyList(connections: List<ApiAgency>, val: string): readonly object[] {
    return ApiAgencyJsonSerializer.instance.toJsonArray(
      connections.filter(x => this.filterConnections(x, val)));
  }

  private filterConnections(x: ApiAgency, val: string): boolean {
    return x.user.exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getCompanyName().exists(s => s.toLowerCase().includes(val.toLowerCase()));
  }

  getAgencies(): List<ApiAgency> {
    return this.apiConnection.agencies.getValue();
  }

  getAgencyIndex(agencyId: number): number {
    return this.getAgencies()
      .findIndex(a => a.getAgencyId().contains(agencyId));
  }

  getConnectionId(): Option<number> {
    return this.apiConnection.selectedState
      .getValue()
      .getConnection()
      .flatMap(c => c.getId());
  }

  private getRows(): Observable<readonly object[]> {
    return combineLatest(this.apiConnection.agencies, this.rowFilter)
      .pipe(debounceTime(300))
      .pipe(filter(x => x.length > 1))
      .pipe(map(([agencies, val]) => this.filterAgencyList(agencies, val)))
      .pipe(shareReplay(1));
  }

  ngOnInit(): void {
    this.apiConnection.agencies.next(this.apiConnection
      .selectedState
      .getValue()
      .getAvailableAgencies());
  }

  openAddAgentModal(): void {
    this.modalService.open(AddAgentModalComponent, {size: 'xl'});
  }

  openEditAgentModal(apiAgency: ApiAgency): void {
    const modalRef = this.modalService.open(EditAgentModalComponent, {size: 'sm'});
    modalRef.componentInstance.agency = ApiAgencyJsonSerializer.instance.fromJson(apiAgency);
  }

  removeAgencyFromSubject(agencyIndex: number): List<ApiAgency> {
    return this.getAgencies()
      .remove(agencyIndex);
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase().trim());
  }
}
