import {Component, Input, OnInit} from '@angular/core';
import {DidgigoApiService, UserService} from '@didgigo/lib-angular';
import {LatLongLocation, OptionUtils, Product, ProductSearch, SearchRadius} from '@didgigo/lib-ts';
import {Either, Left, None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, from, Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ToastHandlerService} from '../services/toast-handler-service';

class ProductSearchResult {
  constructor(
    readonly product: Product,
  ) {
  }

  getProductName(): Option<string> {
    return this.product.getProductName();
  }

  getSupplierId(): Option<string> {
    return this.product.getSupplierName();
  }

  getSupplierName(): Option<string> {
    return this.product.getSupplierName();
  }
}

export class ProductSearchFilters {
  constructor(
    readonly text: string,
    readonly type: 'Accommodation' | 'Day Tour / Attraction' | 'Destination' | 'Multiday',
    readonly owner: 'All' | 'Proposal Company' | 'Supplier',
    readonly radius: SearchRadius = 'Radius-10',
    readonly latLong: Option<LatLongLocation>,
  ) {
  }

  getProductSearchParameters(): List<ProductSearch> {
    return OptionUtils.toList(
      Some(new ProductSearch(Some(this.text), 'Levenshtein-90', 'Fuzzy')),
      this.latLong.flatMap(x => x.getAsText())
        .filter(_ => this.radius !== 'Radius-∞')
        .map(l => new ProductSearch(Some(l), this.radius, 'LatLong')),
      Option.of(this.owner).filter(x => x !== 'All').map(x => new ProductSearch(Some(x), 'Exact', 'Company:Type')),
      Some(new ProductSearch(Some(this.type), 'Exact', 'Type')));
  }
}

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {

  constructor(
    readonly api: DidgigoApiService,
    readonly user: UserService,
    readonly toast: ToastHandlerService,
  ) {
  }

  filter: BehaviorSubject<ProductSearchFilters>;

  @Input()
  latLong: Option<LatLongLocation> = None;

  results: Observable<List<ProductSearchResult>> =
    this.getSearchResults()
      .pipe(this.toast.displayErrorsAndRecoverList('Error getting search results'));

  @Input()
  text: Option<string> = None;

  @Input()
  type: 'Accommodation' | 'Day Tour / Attraction' | 'Destination' | 'Multiday';

  // This was added as a quickfix for the security / GDPR / privacy issue that was reported relating to ePresentations in April 2023
  // It is not meant to be permanent
  getQsidForEpresentations(qsid: string): string {
    const proposalId = atob(qsid).substr(2);
    return btoa(`1234567890123456${proposalId}1234567890123456`).replace('=', '');
  }

  private getSearchResults(): Observable<Either<string, List<ProductSearchResult>>> {
    const filter = this.filter
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged());
    return combineLatest(this.user.getUsingAsCompanyId(), filter)
      .pipe(switchMap(([cid, f]) => {
        if (cid.isEmpty()) {
          return of(Left('Invalid using as company id'));
        }

        return from(this.api.searchProducts(cid.get(), f.getProductSearchParameters()));
      })).pipe(map(pse => pse.map(ps => ps.map(p => new ProductSearchResult(p)))));
  }

  ngOnInit(): void {
    this.resetFilter();
  }

  resetFilter(): void {
    this.filter.next(new ProductSearchFilters(this.text.getOrElse(''), this.type, 'All', 'Radius-10', this.latLong));
  }

  updateFilter(event): void {
    this.filter.next(event.target.value.toLowerCase().trim());
  }
}
