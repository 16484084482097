import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-reports-tab',
  templateUrl: './reports-tab.component.html',
  styleUrls: ['./reports-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
