import {Inject, Injectable} from '@angular/core';
import {Api, DidgigoIngestionApi} from '@didgigo/lib-ts';

@Injectable({
  providedIn: 'root',
})
export class IngestionService extends DidgigoIngestionApi {

  constructor(@Inject('ingestionServer') public ingestionServer: string) {
    super(ingestionServer);
  }

}
