import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HighlightMatchPipeModule} from '@didgigo/lib-angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ProductScoreComponent} from './product-score.component';

@NgModule({
  declarations: [
    ProductScoreComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HighlightMatchPipeModule,
    NgbModule,
    CommonModule,
  ],
  exports: [ProductScoreComponent],
})
export class ProductScoreModule {
  constructor() {
  }
}
