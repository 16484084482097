import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ReplaySubject} from 'rxjs';
import {BookingAnalyzerModalComponent} from '../booking-analyzer-modal/booking-analyzer-modal.component';
import {ApiConnectionService} from '../services/api-connection.service';

@Component({
  selector: 'app-manual-import-tab',
  templateUrl: './manual-import-tab.component.html',
  styleUrls: ['./manual-import-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualImportTabComponent {

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly apiConnection: ApiConnectionService,
    readonly modalService: NgbModal,
  ) {
  }

  resultid: ReplaySubject<number> = new ReplaySubject<number>(1);

  async import(value: string): Promise<void> {
    this.spinner.show('manual-import');
    const result = await this.apiConnection.importManualBookingForSelectedAgency(value);
    this.spinner.hide('manual-import');
    if (!result.getId().nonEmpty()) {
      this.resultid.next(result.getId().get());
    }
  }

  openModal(data: string): void {
    const modalRef = this.modalService.open(BookingAnalyzerModalComponent, {size: 'full'});
    modalRef.componentInstance.data = data;
  }
}
