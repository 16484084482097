import {Component, OnInit, ViewChild} from '@angular/core';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {ApiConnection, ApiConnectionJsonSerializer} from '@didgigo/lib-ts';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay} from 'rxjs/operators';
import {ApiConnectionService} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';

@Component({
  selector: 'app-list-api-connections',
  templateUrl: './list-api-connections.component.html',
  styleUrls: ['./list-api-connections.component.scss'],
})
export class ListApiConnectionsComponent implements OnInit {

  constructor(
    readonly apiConnection: ApiConnectionService,
    readonly api: DidgigoApiService,
    readonly excel: ExcelCreatorService,
  ) {
    this.rows = this.getRows();
  }

  rowFilter: BehaviorSubject<string> = new BehaviorSubject('');

  rows: Observable<readonly object[]>;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  private filterConnectionList(connections: List<ApiConnection>, val: string): readonly object[] {
    return ApiConnectionJsonSerializer.instance.toJsonArray(
      connections.filter(x => this.filterConnections(x, val)));
  }

  private filterConnections(x: ApiConnection, val: string): boolean {
    return x.url.exists(s => s.getHref().toLowerCase().includes(val.toLowerCase()))
      || x.label.exists(s => s.toLowerCase().includes(val.toLowerCase()));
  }

  private getRows(): Observable<readonly object[]> {
    return combineLatest(this.apiConnection.selectedState, this.rowFilter)
      .pipe(debounceTime(300))
      .pipe(filter(x => x.length > 1))
      .pipe(map(([apis, val]) => this.filterConnectionList(apis.getAvailableConnections(), val)))
      .pipe(shareReplay(1));
  }

  ngOnInit(): void {
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase().trim());

  }
}
