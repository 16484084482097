import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {ApiTemplate, ApiTemplateJsonSerializer} from '@didgigo/lib-ts';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay} from 'rxjs/operators';
import {ApiConnectionService} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';

@Component({
  selector: 'app-list-onboarding-templates',
  templateUrl: './list-onboarding-templates.component.html',
  styleUrls: ['./list-onboarding-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOnboardingTemplatesComponent implements OnInit {

  constructor(
    readonly apiConnection: ApiConnectionService,
    readonly api: DidgigoApiService,
    readonly excel: ExcelCreatorService,
  ) {
    this.rows = this.getRows();
  }

  rowFilter: BehaviorSubject<string> = new BehaviorSubject('');

  rows: Observable<readonly object[]>;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  private filterTemplateList(connections: List<ApiTemplate>, val: string): readonly object[] {
    return ApiTemplateJsonSerializer.instance.toJsonArray(
      connections.filter(x => this.filterTemplates(x, val)));
  }

  private filterTemplates(x: ApiTemplate, val: string): boolean {
    return x.shortCode.exists(s => s.toLowerCase().includes(val.toLowerCase()));
  }

  private getRows(): Observable<readonly object[]> {
    return combineLatest(this.apiConnection.selectedState, this.rowFilter)
      .pipe(debounceTime(300))
      .pipe(filter(x => x.length > 1))
      .pipe(map(([apis, val]) => this.filterTemplateList(apis.getAvailableTemplates(), val)))
      .pipe(shareReplay(1));
  }

  ngOnInit(): void {
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase().trim());

  }
}
