import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HighlightMatchPipeModule} from '@didgigo/lib-angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BookingMessageDropdownComponent} from "./booking-message-dropdown.component";

@NgModule({
  declarations: [
    BookingMessageDropdownComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HighlightMatchPipeModule,
    NgbModule,
    CommonModule,
  ],
  exports: [BookingMessageDropdownComponent],
})
export class BookingMessageDropdownModule {
  constructor() {
  }
}
