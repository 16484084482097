import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-agencies-tab',
  templateUrl: './agencies-tab.component.html',
  styleUrls: ['./agencies-tab.component.scss'],
})
export class AgenciesTabComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
