import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DidgigoApiService, UserService} from '@didgigo/lib-angular';
import {ComparisonUtils, Proposal, ProposalEntry} from '@didgigo/lib-ts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {List} from 'immutable';
import {Moment} from 'moment-timezone';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ApiReferenceAnalyzerModalComponent} from '../api-reference-analyzer-modal/api-reference-analyzer-modal.component';
import {ProposalImportEndModalComponent} from '../proposal-import-end-modal/proposal-import-end-modal.component';
import {ApiConnectionService} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';
import {ToastHandlerService} from '../services/toast-handler-service';

@Component({
  selector: 'app-analyze-entries',
  templateUrl: './analyze-entries.component.html',
  styleUrls: ['./analyze-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyzeEntriesComponent implements OnInit {

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly apiConnection: ApiConnectionService,
    readonly api: DidgigoApiService,
    readonly modalService: NgbModal,
    readonly toasts: ToastHandlerService,
    readonly excel: ExcelCreatorService,
    readonly user: UserService,
  ) {
    this.spinner.show();
  }

  @Input()
  proposal: Proposal;

  rowFilter: BehaviorSubject<string> = new BehaviorSubject(' ');

  rows: Observable<readonly object[]>;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  dateComparator(valueA: Moment | string, valueB: Moment | string): number {
    if (typeof valueA === 'string' || typeof valueB === 'string') {
      return -1;
    }
    return ComparisonUtils.dayComparator.compare(valueA, valueB);
  }

  private filterEntries(x: ProposalEntry, val: string): boolean {
    return x.getReference().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getTitle().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getType().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getStatus().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getId().exists(s => s.toString().includes(val))
      || x.getStartDay().exists(s => s.format('MMM dd, YYYY').toLowerCase().includes(val.toLowerCase()));
  }

  private filterProposalEntryList(entries: List<ProposalEntry>, val: string): readonly object[] {
    return entries
      .filter(x => this.filterEntries(x, val))
      .map(x => {
        return ({
          title: x.title.getOrElse(''),
          warning: x.getImportError().getOrElse(''),
          id: x.id.getOrElse(''),
          productId: x.getProductId().getOrElse(''),
          type: x.getType().getOrElse(''),
          reference: x.getProductApiReference().getOrElse(''),
          travelDate: x.start.flatMap(s => s.time).getOrElse(''),
        });
      }).toArray();
  }

  private getEntries(): List<ProposalEntry> {
    return this.proposal.getSortedEntries();
  }

  getRowHeight(row): number {
    const length = row.title.split('\n').filter(x => x.trim() !== '').length;
    switch (length) {
      case 1:
        return 50;
      case 2:
        return 65;
      default:
        return 80;
    }
  }

  private getRows(): Observable<readonly object[]> {
    return this.rowFilter
      .pipe(map(val => this.filterProposalEntryList(this.getEntries(), val)))
      .pipe(shareReplay(1));
  }

  async import(): Promise<void> {
    this.spinner.show('import');
    const result = await this.apiConnection.importBookingForSelectedAgency(this.proposal.reference.get());
    this.spinner.hide('import');
    if (result.getId().nonEmpty()) {
      this.openProposalModal(this.proposal.reference.get(), result.getId().get());
    }
  }

  ngOnInit(): void {
    this.rows = this.getRows();
  }

  openModal(optcode: string, productId: number): void {
    const modalRef = this.modalService.open(ApiReferenceAnalyzerModalComponent, {size: 'full'});
    modalRef.componentInstance.apiReference = optcode;
    modalRef.componentInstance.productId = productId;
  }

  openProposalModal(reference: string, proposalId: number): void {
    const modalRef = this.modalService.open(ProposalImportEndModalComponent, {size: 'small'});
    modalRef.componentInstance.reference = reference;
    modalRef.componentInstance.proposalId = proposalId;
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase());
  }
}
