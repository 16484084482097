import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {ApplicationInsights, SeverityLevel} from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class AzureLoggingService implements ErrorHandler {

  constructor(@Inject('applicationInsightsKey') private applicationInsightsKey: string) {
    this.applicationInsights.loadAppInsights();
  }

  applicationInsights: ApplicationInsights = new ApplicationInsights({
    config: {
      instrumentationKey: this.applicationInsightsKey,
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true,
      enableAjaxErrorStatusText: true,
    }
  });

  handleError(error: any): void {
    this.applicationInsights.trackException({exception: error, severityLevel: SeverityLevel.Critical});
  }

}
