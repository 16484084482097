import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {Api, Proposal, ProposalJsonSerializer} from '@didgigo/lib-ts';
import {Option} from 'funfix-core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ReplaySubject} from 'rxjs';
import {ApiConnectionService} from '../services/api-connection.service';

@Component({
  selector: 'app-booking-analyzer',
  templateUrl: './booking-analyzer.component.html',
  styleUrls: ['./booking-analyzer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingAnalyzerComponent implements OnInit {

  constructor(
    readonly connection: ApiConnectionService,
    readonly didgigoApi: DidgigoApiService,
    readonly spinner: NgxSpinnerService,
  ) {
    this.spinner.show();
  }

  @Input()
  data: string;

  importProposal: ReplaySubject<Proposal> = new ReplaySubject(1);
  importProposalJson: ReplaySubject<object> = new ReplaySubject(1);
  latestProposal: ReplaySubject<object> = new ReplaySubject(1);

  @Input()
  proposalId: number;

  @Input()
  reference: string;
  rfXml: ReplaySubject<object> = new ReplaySubject(1);
  xml: ReplaySubject<object> = new ReplaySubject(1);

  ngOnInit(): void {
    if (Option.of(this.reference).exists(x => x.trim() !== '')) {
      this.connection.getBookingXmlForSelectedAgencyAsJson(this.reference)
        .then(x => this.xml.next(x));

      this.connection.getBookingForSelectedAgency(this.reference)
        .then(x => {
          this.importProposal.next(x);
          this.importProposalJson.next(ProposalJsonSerializer.instance.toJson(x));
        });

      if (this.proposalId !== undefined) {
        this.didgigoApi.getProposalById(this.proposalId)
          .then(x => this.latestProposal.next(ProposalJsonSerializer.instance.toJson(x.getOrElse(new Proposal()))));
      } else {
        this.latestProposal.next(new Proposal());
      }
      if (this.connection.isCCRS()) {
        this.connection.getBookingRFXmlForSelectedAgencyAsJson(this.reference)
          .then(x => this.rfXml.next(x));
      }

    } else if (Option.of(this.data).exists(x => x.trim() !== '')) {
      this.connection.getBookingForDataXmlForSelectedAgencyAsJson(this.data)
        .then(x => this.xml.next(x));

      this.connection.getBookingDataForSelectedAgency(this.data)
        .then(x => {
          this.importProposal.next(x);
          this.importProposalJson.next(ProposalJsonSerializer.instance.toJson(x));
        });
    }
  }
}
