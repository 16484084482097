import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {AzureLoggingService} from './services/azure-logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  constructor(
    config: NgbTooltipConfig,
    private azureLoggingService: AzureLoggingService
  ) {
    config.tooltipClass = 'didgigo-tooltip';
  }
}

