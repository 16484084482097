import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProductScore, Rating} from '@didgigo/lib-ts';

@Component({
  selector: 'app-product-score',
  templateUrl: './product-score.component.html',
  styleUrls: ['./product-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductScoreComponent implements OnInit {

  constructor() {
  }

  @Input()
  overall: Rating;

  @Input()
  score: ProductScore;

  ngOnInit(): void {
  }
}
