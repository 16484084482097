import {Component, OnInit} from '@angular/core';
import {UserService} from '@didgigo/lib-angular';
import {IngestionService} from '../services/ingestion.service';

@Component({
  selector: 'app-dashboard-tab',
  templateUrl: './dashboard-tab.component.html',
  styleUrls: ['./dashboard-tab.component.scss'],
})
export class DashboardTabComponent implements OnInit {

  constructor(
    readonly userService: UserService,
    readonly ingestionService: IngestionService,
  ) { }

  ngOnInit(): void {
  }

}
