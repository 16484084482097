import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-onboarding-tab',
  templateUrl: './onboarding-tab.component.html',
  styleUrls: ['./onboarding-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingTabComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
