import {Component, Input, OnInit} from '@angular/core';
import {ApiAgency, Company, CompanySummary, User} from '@didgigo/lib-ts';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {Observable} from 'rxjs';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {ApiConnectionService} from '../../services/api-connection.service';
import {ToastHandlerService} from '../../services/toast-handler-service';

@Component({
  selector: 'app-edit-agent-modal',
  templateUrl: './edit-agent-modal.component.html',
  styleUrls: ['./edit-agent-modal.component.scss']
})
export class EditAgentModalComponent implements OnInit {

  constructor(
    readonly apiConnection: ApiConnectionService,
    readonly modalService: NgbActiveModal,
    readonly api: DidgigoApiService,
    readonly notificationService: ToastHandlerService,
  ) {
  }

  @Input()
  agency: Option<ApiAgency> = None;

  companies: List<Company> = List();

  loading: boolean;

  login = '';
  password = '';

  selectedCompany: Option<Company> = None;

  agencyNameExtractor = (c: Company) => c.getName();

  companyLabeller = (c: Company) => c.getName().getOrElse('Present As');

  editAgencySubject(agencyIndex: number, newAgency: ApiAgency): List<ApiAgency> {
    return this.getAgencies()
      .remove(agencyIndex)
      .concat(newAgency);
  }

  editAgentCompanyApiMapping(): void {
    const apiAgency = ApiAgency.forAgentCompanyMapping(
      this.getLogin(),
      this.getPassword(),
      this.apiConnection.getCurrentUsingAsCompany(),
      this.selectedCompany,
    );
    Option.map3(this.getCurrentConnectionId(), this.getCurrentUserName(), this.getAgencyId(), async (connId, modifiedBy, aid) => {
      const request = await this.api.editApiCompanyAgentMapping(apiAgency, connId, modifiedBy);
      if (request.isLeft()) {
        this.notificationService.showError('Error', request.value);
        return;
      }
      this.notificationService.showSuccess('Success', 'Successfully edited agency');
      this.apiConnection.agencies.next(this.editAgencySubject(this.getAgencyIndex(aid), apiAgency));
    });
  }

  getAgencies(): List<ApiAgency> {
    return this.apiConnection.agencies.getValue();
  }

  getAgency(): Option<ApiAgency> {
    return this.agency;
  }

  getAgencyId(): Option<number> {
    return this.getAgency()
      .flatMap(a => a.getAgencyId());
  }

  getAgencyIndex(agencyId: number): number {
    return this.getAgencies()
      .findIndex(a => a.getAgencyId().contains(agencyId));
  }

  getAgencyName(): Option<string> {
    return this.getAgency()
      .flatMap(a => a.getCompanyName());
  }

  getAgentLogin(): Option<string> {
    return this.getAgency()
      .flatMap(a => a.getUser());
  }

  getAgentPassword(): Option<string> {
    return this.getAgency()
      .flatMap(a => a.getPassword());
  }

  getAvailableCompanies(): Observable<List<Company>> {
    this.loading = true;
    return this.apiConnection.listProposalCompanies();
  }

  getCompanyImage(): Option<string> {
    return this.selectedCompany
      .flatMap(x => x.getLogo())
      .flatMap(x => x.getHref());
  }

  getCurrentConnectionId(): Option<number> {
    return this.apiConnection.selectedState
      .getValue()
      .getConnection()
      .flatMap(x => x.getId());
  }

  getCurrentUser(): Option<User> {
    return this.apiConnection.selectedState
      .getValue()
      .getUser();
  }

  getCurrentUserName(): Option<string> {
    return this.getCurrentUser()
      .flatMap(u => u.getLoggedInAgentName());
  }

  getLogin(): Option<string> {
    return Option.of(this.login);
  }

  getPassword(): Option<string> {
    return Option.of(this.password);
  }

  ngOnInit(): void {
    this.getAvailableCompanies()
      .subscribe(cs => {
        this.companies = cs;
        this.loading = false;
      });
  }

  updateSelectedCompany(event: Company): void {
    this.selectedCompany = Some(event);
  }

}
