import {Injectable} from '@angular/core';
import {DidgigoApiService, UserService} from '@didgigo/lib-angular';
import {
  Api,
  ApiAgency,
  ApiCache,
  ApiConnection,
  ApiTemplate,
  CollectionUtils,
  Company,
  ComparisonUtils,
  deepDeleteKeys,
  deepMap,
  EitherUtils,
  MappingDetails,
  OptionUtils,
  Product,
  ProductOption,
  PromiseUtils,
  Proposal,
  ProposalCache,
  ProposalEntry,
  ProposalLike,
  ServiceCodeAnalysis,
  User,
  XmlUtils,
} from '@didgigo/lib-ts';
import {Either, None, Option, Some} from 'funfix-core';
import {List, Map} from 'immutable';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, shareReplay, switchMap, tap} from 'rxjs/operators';
import {PropertyComparator} from 'ts-comparators';
import {IngestionService} from './ingestion.service';
import {ToastHandlerService} from './toast-handler-service';

const apiComparator = new PropertyComparator<Api, 'name'>('name', ComparisonUtils.optionStringComparator);
const agencyComparator = new PropertyComparator<ApiAgency, 'user'>('user', ComparisonUtils.optionStringComparator);
const connectionComparator = new PropertyComparator<ApiConnection, 'label'>('label', ComparisonUtils.optionStringComparator);
const templateComparator = new PropertyComparator<ApiTemplate, 'shortCode'>('shortCode', ComparisonUtils.optionStringComparator);
const connectionStateApiComparator =
  new PropertyComparator<ConnectionState, 'api'>('api', ComparisonUtils.getOptionComparator(apiComparator))
    .then(new PropertyComparator<ConnectionState, 'agency'>('agency', ComparisonUtils.getOptionComparator(agencyComparator)))
    .then(new PropertyComparator<ConnectionState, 'connection'>('connection', ComparisonUtils.getOptionComparator(connectionComparator)))
    .then(new PropertyComparator<ConnectionState, 'template'>('template', ComparisonUtils.getOptionComparator(templateComparator)));

export class ConnectionState {
  constructor(
    readonly apis: Option<ApiCache> = None,
    readonly api: Option<Api> = None,
    readonly agency: Option<ApiAgency> = None,
    readonly connection: Option<ApiConnection> = None,
    readonly template: Option<ApiTemplate> = None,
    readonly user: Option<User> = None) {
  }

  canHideDropDowns(): boolean {
    return this.hasOnlyOneConnectionAndAgency() && this.isComplete();
  }

  equals(other: ConnectionState): boolean {
    console.log('Equals', connectionStateApiComparator.compare(this, other) === 0);
    return connectionStateApiComparator.compare(this, other) === 0;
  }

  getAgency(): Option<ApiAgency> {
    return this.agency;
  }

  getApiCache(): Option<ApiCache> {
    return this.apis;
  }

  // Just a redefinition for getApiCache()
  getApis(): Option<ApiCache> {
    return this.apis;
  }

  getAvailableAgencies(): List<ApiAgency> {
    return this.connection.map(x => x.getAgencies()).getOrElse(List<ApiAgency>())
      .sort((a, b) => agencyComparator.compare(a, b));
  }

  getAvailableConnections(): List<ApiConnection> {
    return this.apis.map(x => x.listConnections()).getOrElse(List<ApiConnection>())
      .filter(x => !x.getAgencies().isEmpty())
      .sort((a, b) => connectionComparator.compare(a, b));
  }

  getAvailableTemplates(): List<ApiTemplate> {
    return Option.map2(this.apis, this.connection, (apis, conn) => apis.getTemplatesForConnection(conn)).getOrElse(List<ApiTemplate>())
      .sort((a, b) => templateComparator.compare(a, b));
  }

  getConnection(): Option<ApiConnection> {
    return this.connection;
  }

  getFirstConnection(): Option<ApiConnection> {
    return this.connection.orElse(Option.of(this.getAvailableConnections().first()));
  }

  getIncompleteError(): Option<string> {
    if (this.isComplete()) {
      return None;
    } else if (this.user.isEmpty()) {
      return Some('Missing user information');
    } else if (this.template.isEmpty()) {
      return Some('Missing api template in information');
    } else if (this.apis.isEmpty()) {
      return Some('Missing accessible api information');
    } else if (this.api.isEmpty()) {
      return Some('Missing selected api information');
    } else if (this.apis.isEmpty()) {
      return Some('Missing selected agency information');
    }
    return Some('Unknown Error');
  }

  getSelfIfComplete(): Either<string, ConnectionState> {
    return EitherUtils.fromErrorOpt(this, this.getIncompleteError());
  }

  getTemplate(): Option<ApiTemplate> {
    return this.template;
  }

  getUser(): Option<User> {
    return this.user;
  }

  hasOnlyOneConnectionAndAgency(): boolean {
    return !(this.getAvailableConnections().size > 1 || this.getAvailableAgencies().size > 1 || this.getAvailableTemplates().size > 1);
  }

  isComplete(): boolean {
    return this.agency.nonEmpty()
      && this.connection.nonEmpty()
      && this.template.nonEmpty()
      && this.api.nonEmpty()
      && this.apis.nonEmpty()
      && this.user.nonEmpty();
  }

  isDifferent(other: ConnectionState): boolean {
    return !this.equals(other);
  }

  isTourplan(): boolean {
    return this.api.exists(x => x.isTourplan());
  }

  withAgency(agency: ApiAgency): ConnectionState {
    return new ConnectionState(
      this.apis,
      this.api,
      Some(agency),
      this.connection,
      OptionUtils.flatMap2(this.apis, this.connection, (a, c) => a.getBestTemplateForAgency(c, agency)),
      this.user);
  }

  withApis(apis: ApiCache): ConnectionState {
    return new ConnectionState(
      Some(apis),
      None,
      None,
      None,
      None,
      this.user);
  }

  withConnection(connection: ApiConnection): ConnectionState {

    // Only set an agency when there is one agency
    const agencyToUse = Option.of(connection.agencies.first())
      .filter(x => connection.agencies.size === 1);

    return new ConnectionState(
      this.apis,
      this.apis.flatMap(x => x.getApiForConnection(connection)),
      agencyToUse,
      Some(connection),
      OptionUtils.flatMap2(this.apis, agencyToUse, (a, ag) => a.getBestTemplateForAgency(connection, ag)),
      this.user);
  }

  withTemplate(template: ApiTemplate): ConnectionState {
    return new ConnectionState(
      this.apis,
      this.api,
      this.agency,
      this.connection,
      Some(template),
      this.user);
  }

  withUser(user: User): ConnectionState {
    return new ConnectionState(
      None,
      None,
      None,
      None,
      None,
      Some(user));
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApiConnectionService {

  constructor(
    readonly didgigoService: DidgigoApiService,
    readonly ingestion: IngestionService,
    readonly toasts: ToastHandlerService,
    readonly xml2jsonService: NgxXml2jsonService,
    readonly user: UserService) {

    this.user.userState
      .pipe(filter(x => x.isReady()))
      .pipe(tap(x => this.selectedState.next(this.selectedState.getValue().withUser(x))))
      .subscribe(_ => this.listApisAccessible().then(x => this.selectedState.next(this.selectedState.getValue().withApis(x))));

    this.mappings = this.listMappingsForSelectedAgency();
  }

  agencies: BehaviorSubject<List<ApiAgency>> = new BehaviorSubject(List());

  mappings: Observable<List<MappingDetails>>;

  selectedState: BehaviorSubject<ConnectionState> = new BehaviorSubject(new ConnectionState());

  clear(): void {
    this.selectedState.next(new ConnectionState());
  }

  convertXmlDocumentToJson(data: Document): object {
    const sanitized = this.xml2jsonService.xmlToJson(data);
    const obj = deepDeleteKeys(sanitized, '#text');
    return deepMap(obj, v => v === {} ? '' : v);
  }

  convertXmlStringToJson(res: string): object {
    const data = XmlUtils.parse(res);
    return data.map(x => this.convertXmlDocumentToJson(x)).getOrElse({});
  }

  async getBookingDataForSelectedAgency(reference: string): Promise<Proposal> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBookingForData(reference, a, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving booking', response, new Proposal());
  }

  async getBookingForDataXmlForSelectedAgency(data: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBookingXmlForData(a, data, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving booking xml', response);
  }

  async getBookingForDataXmlForSelectedAgencyAsJson(reference: string): Promise<object> {
    const res = await this.getBookingForDataXmlForSelectedAgency(reference);
    return this.convertXmlStringToJson(res);
  }

  async getBookingForSelectedAgency(reference: string): Promise<Proposal> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBooking(reference, a, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving booking', response, new Proposal());
  }

  async getBookingMessageForSelectedAgency(reference: string, message: string, format: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBookingMessageDataAsString(reference, message, format, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving booking message data', response);
  }

  async getBookingMessageLabelsForSelectedAgency(reference: string): Promise<List<string>> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBookingMessageLabels(reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverList('Error retrieving booking message labels', response);
  }

  async getBookingRFXmlForSelectedAgency(reference: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBookingRFXml(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving booking RF xml', response);
  }

  async getBookingRFXmlForSelectedAgencyAsJson(reference: string): Promise<object> {
    const res = await this.getBookingRFXmlForSelectedAgency(reference);
    return this.convertXmlStringToJson(res);
  }

  async getBookingXmlForSelectedAgency(reference: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getBookingXml(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving booking xml', response);
  }

  async getBookingXmlForSelectedAgencyAsJson(reference: string): Promise<object> {
    const res = await this.getBookingXmlForSelectedAgency(reference);
    return this.convertXmlStringToJson(res);
  }

  getCurrentAccessibleCompanies(): List<Company> {
    return this.selectedState.getValue().user.map(x => x.getAccessibleCompanies())
      .getOrElse(List());
  }

  getCurrentUsingAsCompany(): Option<Company> {
    return this.selectedState.getValue().user.flatMap(x => x.getCompany());
  }

  getCurrentUsingAsCompanyId(): Option<number> {
    return this.selectedState.getValue().user.flatMap(x => x.getUsingAsCompanyId());
  }

  async getDpProductApiReferenceXmlForSelectedAgency(reference: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getDpProductApiReferenceXml(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving dp xml', response);
  }

  async getDpProductApiReferenceXmlForSelectedAgencyAsJson(reference: string): Promise<object> {
    const res = await this.getDpProductApiReferenceXmlForSelectedAgency(reference);
    return this.convertXmlStringToJson(res);
  }

  async getMissingMappingsForSelectedAgency(): Promise<List<string>> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.listMissingProductReferences(a, b, c, d, e));
    return this.toasts.showErrorAndRecoverList('Error listing locations', response);
  }

  async getProductApiReferenceAsProductForSelectedAgency(reference: string): Promise<Product> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getProductApiReferenceAsProduct(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving product reference as product', response, new Product());
  }

  async getProductApiReferenceAsProductOptionForSelectedAgency(reference: string): Promise<ProductOption> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getProductApiReferenceAsProductOption(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving product reference as product option', response, new ProductOption());
  }

  async getProductApiReferenceAsProposalEntryForSelectedAgency(reference: string): Promise<ProposalEntry> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getProductApiReferenceAsProposalEntry(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving product reference as proposal entry', response, new ProposalEntry());
  }

  async getProductApiReferenceXmlForSelectedAgency(reference: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getProductApiReferenceXml(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving product reference xml', response);
  }

  async getProductApiReferenceXmlForSelectedAgencyAsJson(reference: string): Promise<object> {
    const res = await this.getProductApiReferenceXmlForSelectedAgency(reference);
    return this.convertXmlStringToJson(res);
  }

  async getSupplierApiReferenceAsCompanyForSelectedAgency(reference: string): Promise<Company> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getSupplierApiReferenceAsCompany(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving reference as company', response, new Company());
  }

  async getSupplierApiReferenceAsProductForSelectedAgency(reference: string): Promise<Product> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getSupplierApiReferenceAsProduct(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error retrieving reference as product', response, new Product());
  }

  async getSupplierApiReferenceXmlForSelectedAgency(reference: string): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getSupplierApiReferenceXml(a, reference, b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving supplier api reference xml', response);
  }

  async getSupplierInfoXmlForSelectedAgencyAsJson(supcode: string): Promise<object> {
    const res = await this.getSupplierApiReferenceXmlForSelectedAgency(supcode);
    return this.convertXmlStringToJson(res);
  }

  async getSystemSettingsXmlForSelectedAgency(): Promise<string> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.getSystemSettingsXml(b, c, d, e));
    return this.toasts.showErrorAndRecoverString('Error retrieving system settings xml', response);
  }

  async getSystemSettingsXmlForSelectedAgencyAsJson(): Promise<object> {
    const res = await this.getSystemSettingsXmlForSelectedAgency();
    return this.convertXmlStringToJson(res);
  }

  async importBookingForSelectedAgency(reference: string): Promise<Proposal> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.importBooking(reference, a, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error importing booking', response, new Proposal());
  }

  async importBookingMessageForSelectedAgency(reference: string): Promise<Proposal> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.importBookingMessage(reference, a, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error importing booking', response, new Proposal());
  }

  async importManualBookingForSelectedAgency(value: string): Promise<Proposal> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.importManualBooking(value, a, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error importing booking', response, new Proposal());
  }

  async importMissingMappingsForSelectedAgency(): Promise<List<number>> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.importMissingProductReferences(a, b, c, d, e));
    return this.toasts.showErrorAndRecoverList('Error listing locations', response);
  }

  async importProductForSelectedAgency(reference: string): Promise<number> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.importProduct(reference, a, b, c, d, e));
    return this.toasts.showErrorAndRecoverEither('Error importing booking', response, -1);
  }

  isCCRS(): boolean {
    return this.selectedState.getValue().api.exists(x => x.isCcrs());
  }

  isProductImportOnly(): boolean {
    return this.selectedState.getValue().api.exists(x => x.isProductImportOnly());
  }

  isTourplan(): boolean {
    return this.selectedState.getValue().isTourplan();
  }

  isTourplanTemplate(): boolean {
    return this.selectedState.getValue().api.exists(x => x.isTourplan());
  }

  listApisAccessible(): Promise<ApiCache> {
    const companyId = this.selectedState.getValue().user.flatMap(x => x.getUsingAsCompanyId());
    return PromiseUtils.sequenceEitherPromise(
      EitherUtils.toEither(companyId, 'Missing company id').map(id => this.didgigoService.listApisAccessibleToCompany(id)))
      .then(x => this.toasts.showErrorAndRecoverEither('Error listing accessible api connections', x, new ApiCache(List())));
  }

  async listBookingsForSelectedAgency(state: ConnectionState, recent: boolean): Promise<Either<string, List<Proposal>>> {
    if (!state.isComplete()) {
      return state.getSelfIfComplete().map(_ => List<Proposal>());
    }

    const bookings = await this.ingestion.listBookingsForClient(
      state.api.get(),
      state.template.get(),
      state.connection.get(),
      state.agency.get(),
      state.user.get(),
      recent);

    return PromiseUtils.sequenceEitherPromise(
      Either.map2(state.connection.get().getCompanyIdEither(),
        bookings, (cid, proposals) => this.populateDidgigoReferences(proposals, cid)));
  }

  async listLocationsForSelectedAgency(): Promise<Map<string, string>> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.listLocationsForClient(b, c, d, e));
    return this.toasts.showErrorAndRecoverMap('Error listing locations', response);
  }

  listMappings(cid: number, type: 'TPL' | 'AOT' = 'TPL'): Observable<List<MappingDetails>> {
    return from(this.didgigoService.listTourplanMappingRecordsAccessibleToCompany(cid, type))
      .pipe(this.toasts.displayErrorsAndRecoverList('Error listing tourplan mapping records'));
  }

  listMappingsForSelectedAgency(): Observable<List<MappingDetails>> {
    return this.selectedState
      .pipe(distinctUntilChanged())
      .pipe(switchMap(x =>
        x.agency.flatMap(v => v.getCompanyId())
          .orElse(x.user.flatMap(v => v.getUsingAsCompanyId()))
          .map(y => this.listMappings(y)) // TODO: Switch AOT Companies to use AOT Table
          .getOrElse(of(List<MappingDetails>()))))
      .pipe(shareReplay(1));
  }

  listProposalCompanies(): Observable<List<Company>> {
    return from(this.didgigoService.listProposalCompanies())
      .pipe(this.toasts.displayErrorsAndRecoverList('Error listing proposal companies'));
  }

  async listServicesForSelectedAgency(): Promise<List<ServiceCodeAnalysis>> {
    const response = await this.runFunctionWithSnapshotOfCurrentStateAsync(
      (a, b, c, d, e) => this.ingestion.listServicesForClient(b, c, d, e));
    return this.toasts.showErrorAndRecoverList('Error listing services', response);
  }

  observeFunctionWithSnapshotOfCurrentStateAsync<T>(
    f: (a: Api, b: ApiTemplate, c: ApiConnection, d: ApiAgency, e: User) => Promise<Either<string, T>>): Observable<Either<string, T>> {
    return this.selectedState
      .pipe(filter(x => x.isComplete()))
      .pipe(distinctUntilChanged((a, b) => a.equals(b)))
      .pipe(switchMap(s => from(f(s.api.get(), s.template.get(), s.connection.get(), s.agency.get(), s.user.get()))))
      .pipe(shareReplay(1));
  }

  async populateDidgigoReferences(tplBookings: List<Proposal>, cid: number): Promise<Either<string, List<Proposal>>> {
    const bookings = await this.didgigoService.listProposalSummariesByRefs(
      CollectionUtils.collect(tplBookings, x => x.getReference()).toSet(), cid);

    if (bookings.isLeft()) {
      return bookings;
    }

    const cache = new ProposalCache(bookings.get());

    return Either.right(tplBookings.map(booking => {
      const match: Option<ProposalLike> = booking.getReference().flatMap(x => cache.getFirstWithReference(x));
      return match.map(x => booking.merge(x.buildProposal().getOrElse(new Proposal()))).getOrElse(booking);
    }));
  }

  async runFunctionWithSnapshotOfCurrentStateAsync<T>(
    f: (a: Api, b: ApiTemplate, c: ApiConnection, d: ApiAgency, e: User) => Promise<Either<string, T>>): Promise<Either<string, T>> {
    const state = this.selectedState.getValue().getSelfIfComplete();

    return PromiseUtils.sequenceEitherPromise(
      state.map(s => f(s.api.get(), s.template.get(), s.connection.get(), s.agency.get(), s.user.get())));
  }

  selectAgency(ag: ApiAgency): void {
    this.selectedState.next(this.selectedState.getValue().withAgency(ag));
  }

  selectApiTemplate(t: ApiTemplate): void {
    this.selectedState.next(this.selectedState.getValue().withTemplate(t));
  }

  selectConnection(conn: ApiConnection): void {
    this.selectedState.next(this.selectedState.getValue().withConnection(conn));
  }
}
