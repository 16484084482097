import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DidgigoApiService, UserService} from '@didgigo/lib-angular';
import {Distance, OptionUtils, Product} from '@didgigo/lib-ts';
import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiConnectionService} from '../services/api-connection.service';

@Component({
  selector: 'app-api-reference-overview',
  templateUrl: './api-reference-overview.component.html',
  styleUrls: ['./api-reference-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiReferenceOverviewComponent implements OnInit {

  constructor(
    readonly connection: ApiConnectionService,
    readonly api: DidgigoApiService,
    readonly spinner: NgxSpinnerService,
    readonly user: UserService,
  ) {
  }

  @Input()
  apiReference: string;

  @Input()
  importableProduct: Option<Product> = None;

  @Input()
  mappedProduct: Option<Product> = None;

  private areProductsOver1kmApart(): boolean {
    return OptionUtils.exists2(
      this.mappedProduct,
      this.importableProduct, (current, importable) => current.isNear(importable, Distance.km(1)).contains(false));
  }

  private getDistanceBetweenImportableAndMapped(): Option<Distance> {
    return OptionUtils.flatMap2(
      this.mappedProduct,
      this.importableProduct, (current, importable) => current.getStraightLineDistance(importable));
  }

  getImportableProductAddress(): Option<string> {
    return this.importableProduct.flatMap(x => x.getLocation()).flatMap(x => x.getLocationString());
  }

  getImportableProductImageHref(): Option<string> {
    return this.importableProduct.flatMap(x => Option.of(x.getImageUrls().first()));
  }

  getImportableProductLatitude(): Option<number> {
    return this.importableProduct.flatMap(x => x.getLatitude());
  }

  getImportableProductLongitude(): Option<number> {
    return this.importableProduct.flatMap(x => x.getLongitude());
  }

  getImportableProductName(): Option<string> {
    return this.importableProduct.flatMap(x => x.getProductName());
  }

  getImportableProductShortDescription(): Option<string> {
    return this.importableProduct.flatMap(x => x.getShortDescription());
  }

  getImportableProductSupplierName(): Option<string> {
    return this.importableProduct.flatMap(x => x.getSupplierName());
  }

  getImportableProductSupportedLanguageCodes(): Option<List<string>> {
    return this.importableProduct.map(x => x.getSupportedLanguageCodes());
  }

  getImportableProductType(): Option<string> {
    return this.importableProduct.flatMap(x => x.getProductType());
  }

  getMappedProductAddress(): Option<string> {
    return this.mappedProduct.flatMap(x => x.getLocation()).flatMap(x => x.getLocationString());
  }

  getMappedProductDistance(): Option<string> {
    return this.getDistanceBetweenImportableAndMapped().map(x => x.getDistanceString(true));
  }

  getMappedProductId(): Option<number> {
    return this.mappedProduct.flatMap(x => x.getId());
  }

  getMappedProductImageHref(): Option<string> {
    return this.mappedProduct.flatMap(x => this.api.getProductImagePathForProduct(x));
  }

  getMappedProductLatitude(): Option<number> {
    return this.mappedProduct.flatMap(x => x.getLatitude());
  }

  getMappedProductLongitude(): Option<number> {
    return this.mappedProduct.flatMap(x => x.getLongitude());
  }

  getMappedProductName(): Option<string> {
    return this.mappedProduct.flatMap(x => x.getProductName());
  }

  getMappedProductQsid(): Option<string> {
    return this.mappedProduct.flatMap(x => x.getQuickstartId());
  }

  getMappedProductShortDescription(): Option<string> {
    return this.mappedProduct.flatMap(x => x.getShortDescription());
  }

  getMappedProductSupplierName(): Option<string> {
    return this.mappedProduct.flatMap(x => x.getSupplierName());
  }

  getMappedProductSupportedLanguageCodes(): Option<List<string>> {
    return this.mappedProduct.map(x => x.getSupportedLanguageCodes());
  }

  getMappedProductType(): Option<string> {
    return this.mappedProduct.flatMap(x => x.getProductType());
  }

  getWarning(): Option<string> {
    if (this.areProductsOver1kmApart()) {
      if (this.getImportableProductName().nonEmpty() && this.getMappedProductName().nonEmpty()) {
        return this.getDistanceBetweenImportableAndMapped()
          .map(x => `${this.getImportableProductName().get()} (Importable) is ${x.getDistanceString(true)} away from ${this.getMappedProductName().get()} (Mapped)`);
      }
    }
    return None;
  }

  async import(): Promise<void> {
    await this.spinner.show('import-product');
    await this.connection.importProductForSelectedAgency(this.apiReference);
    await this.spinner.hide('import-product');
  }

  ngOnInit(): void {
  }
}
