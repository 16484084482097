import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '@didgigo/lib-angular';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {
  constructor(public user: UserService, public router: Router) {
  }

  canActivate(): boolean {
    if (!this.user.getCurrentUser().isReady()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
