import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelCreatorService {

  createAndSaveSpreadsheet(name: string, data: readonly object[]): any {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([...data]);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    return XLSX.writeFile(wb, `${name}.xlsx`);
  }

}
