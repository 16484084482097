import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '@didgigo/lib-angular';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastHandlerService} from '../services/toast-handler-service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {

  constructor(
    readonly user: UserService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly toasts: ToastHandlerService) {
  }

  email = '';
  password = '';

  async tryLogin(): Promise<void> {
    await this.spinner.show();
    const result = await this.user.login(this.email, this.password);

    if (result.isLeft()) {
      await this.spinner.hide();
      this.toasts.showError('Invalid Login', result.value);
    } else {
      this.router.navigate(['./']);
    }
  }
}
