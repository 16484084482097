import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-api-reference-analyzer-modal',
  templateUrl: './api-reference-analyzer-modal.component.html',
  styleUrls: ['./api-reference-analyzer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiReferenceAnalyzerModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
  ) {
  }

  @Input()
  apiReference: string;

  @Input()
  productId: number | null;

  ngOnInit() {
  }
}
