import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FileSaverService {

  saveB64Pdf(name: string, data: string): void {
    return this.saveB64toFile(data, name, 'application/pdf');
  }

  // tslint:disable:no-loop-statement
  saveB64toFile(b64Data: string, filename: string, contentType: string): void {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    saveAs(new Blob(byteArrays, {type: contentType}), filename);
  }

  saveXml(name: string, data: string): void {
    return saveAs(new Blob([data], {type: 'text/xml'}), name);
  }

  // tslint:enable:no-loop-statement
}
