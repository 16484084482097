import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {deepDeleteKeys, deepMap, filterObject, XmlUtils} from '@didgigo/lib-ts';
import json2xml from 'json2xml';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, take} from 'rxjs/operators';
import {FileSaverService} from '../services/file-saver.service';

@Component({
  selector: 'app-json-tree-viewer',
  templateUrl: './json-tree-viewer.component.html',
  styleUrls: ['./json-tree-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonTreeViewerComponent {

  constructor(
    readonly xml2jsonService: NgxXml2jsonService,
    readonly fileSaver: FileSaverService,
  ) {
  }

  @Input()
  fileName = 'data';

  filter = new BehaviorSubject('');

  filtered: Observable<object> = this.filter
    .pipe(debounceTime(300))
    .pipe(distinctUntilChanged())
    .pipe(map(x => filterObject(x, this.getData())))
    .pipe(map(x => deepMap(x, v => v === {} ? '' : v)))
    .pipe(shareReplay(1));

  @Input()
  json: object = {};

  @Input()
  xml: string;

  getData(): object {
    if (this.xml !== undefined && this.xml !== null) {
      return this.getXmlAsJson();
    }
    return this.json;
  }

  getXmlAsJson(): object {
    const data = XmlUtils.parse(this.xml);
    const sanitized = data.map(x => this.xml2jsonService.xmlToJson(x)).getOrElse({});
    const obj = deepDeleteKeys(sanitized, '#text');
    return deepMap(obj, v => v === {} ? '' : v);
  }

  saveXml(): void {
    this.filtered
      .pipe(take(1))
      .subscribe(data => this.fileSaver.saveXml(this.fileName + '.xml', '<data>' + json2xml(data, {header: false}) + '</data>'));
  }

  updateFilter(event): void {
    this.filter.next(event.target.value.toLowerCase().trim());
  }
}
