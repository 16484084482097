import {AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {BookingMessageDropdownComponent} from '../components/booking-message-dropdown/booking-message-dropdown.component';
import {ApiConnectionService} from '../services/api-connection.service';

@Component({
  selector: 'app-booking-analyzer-messages-tab',
  templateUrl: './booking-analyzer-messages-tab.component.html',
  styleUrls: ['./booking-analyzer-messages-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingAnalyzerMessagesTabComponent implements AfterViewInit {

  constructor(
    readonly connection: ApiConnectionService,
    private sanitizer: DomSanitizer,
  ) {
  }

  bookingMessage: Observable<any>;

  @ViewChild('dropdown', {static: false})
  private bookingMessageDropdown: BookingMessageDropdownComponent;

  @Input()
  reference: string;

  selectedBookingMessageLabel: ReplaySubject<string>;

  ngAfterViewInit(): void {
    this.selectedBookingMessageLabel = this.bookingMessageDropdown.selected;
    this.bookingMessage = this.selectedBookingMessageLabel
      .pipe(distinctUntilChanged())
      .pipe(switchMap(label => this.connection.getBookingMessageForSelectedAgency(this.reference, label, 'pdf')))
      .pipe(map(x => this.sanitizer.bypassSecurityTrustUrl('data:application/pdf;base64,' + x),
      ))
    ;
  }
}
