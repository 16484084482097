import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {UserService} from '@didgigo/lib-angular';
import {Mapping, MappingDetails, Product} from '@didgigo/lib-ts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Some} from 'funfix-core';
import {List} from 'immutable';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay} from 'rxjs/operators';
import {ApiReferenceAnalyzerModalComponent} from '../api-reference-analyzer-modal/api-reference-analyzer-modal.component';
import {ApiConnectionService} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';
import {ToastHandlerService} from '../services/toast-handler-service';

@Component({
  selector: 'app-list-mapping',
  templateUrl: './list-mapping.component.html',
  styleUrls: ['./list-mapping.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMappingComponent {

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly apiConnection: ApiConnectionService,
    readonly modalService: NgbModal,
    readonly toasts: ToastHandlerService,
    readonly user: UserService,
    readonly excel: ExcelCreatorService) {
    this.spinner.show();
    this.allMappings =
      combineLatest(this.apiConnection.mappings, this.missingMappings)
        .pipe(map(([ours, missing]) => ours.concat(missing)))
        .pipe(shareReplay(1));
    this.rows = this.getRows();
  }

  allMappings: Observable<List<MappingDetails>>;

  missingMappings: BehaviorSubject<List<MappingDetails>> = new BehaviorSubject<List<MappingDetails>>(List());

  rowFilter: BehaviorSubject<string> = new BehaviorSubject('');

  rows: Observable<readonly object[]>;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  private filterMapping(x: MappingDetails, val: string): boolean {
    return x.matchesFuzzySearch(val, 'Contains');
  }

  private filterMappingList(mappings: List<MappingDetails>, val: string): object[] {
    return mappings
      .filter(x => this.filterMapping(x, val))
      .map(mapping => ({
        apiReference: mapping.getApiReference().getOrElse(''),
        product: mapping.product.getOrElse(new Product()),
        qsid: mapping.product.flatMap(x => x.getQuickstartId()).getOrElse(''),
        productId: mapping.getProductId().map(x => x.toString()).getOrElse(''),
        productName: mapping.getProductName().getOrElse(''),
        supplierName: mapping.getSupplierName().getOrElse(''),
        productType: mapping.getProductType().getOrElse(''),
        ownerName: mapping.getOwnerName().getOrElse(''),
        warning: mapping.warning.getOrElse(''),
        city: mapping.getProductCity().getOrElse(''),
        country: mapping.getProductCountry().getOrElse(''),
      })).toArray();
  }

  // This was added as a quickfix for the security / GDPR / privacy issue that was reported relating to ePresentations in April 2023
  // It is not meant to be permanent
  getQsidForEpresentations(qsid: string): string {
    const proposalId = atob(qsid).substr(2);
    return btoa(`1234567890123456${proposalId}1234567890123456`).replace('=', '');
  }

  private getRows(): Observable<object[]> {
    return combineLatest(this.allMappings, this.rowFilter)
      .pipe(debounceTime(300))
      .pipe(filter(x => x.length > 1))
      .pipe(map(([mappings, val]) => this.filterMappingList(mappings, val)));
  }

  async importMissing(): Promise<void> {
    this.spinner.show('import');
    const result = await this.apiConnection.importMissingMappingsForSelectedAgency();
    this.spinner.hide('import');
    this.toasts.showSuccess('Import finished', `new Products imported: ${result.size}`);
  }

  openModal(optcode: string, productId: number | null): void {
    const modalRef = this.modalService.open(ApiReferenceAnalyzerModalComponent, {size: 'full'});
    modalRef.componentInstance.apiReference = optcode;
    modalRef.componentInstance.productId = productId;
  }

  async populateMissingMappings(): Promise<void> {
    this.spinner.show('sync');
    const res = await this.apiConnection.getMissingMappingsForSelectedAgency();
    this.spinner.hide('sync');
    this.missingMappings.next(res.map(v => new MappingDetails(Some(new Mapping(Some(v))))));
    this.toasts.showSuccess('Loaded Mappings', `Successfully loaded ${res.size} missing mappings`);
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase().trim());
  }
}
