import {Injectable} from '@angular/core';
import {Either} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {ToastrService} from 'ngx-toastr';
import {Observable, OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToastHandlerService {
  constructor(readonly toasts: ToastrService) {
  }

  // Does not catch errors, only displays lefts
  displayErrorsAndRecover<T>(label: string, fallback: T): OperatorFunction<Either<string, T>, T> {
    return (o: Observable<Either<string, T>>) => {
      return o.pipe(map(e => this.showErrorAndRecoverEither(label, e, fallback)));
    };
  }

  // Does not catch errors, only displays lefts
  displayErrorsAndRecoverList<T>(label: string): OperatorFunction<Either<string, List<T>>, List<T>> {
    return (o: Observable<Either<string, List<T>>>) => {
      return o.pipe(map(e => this.showErrorAndRecoverList(label, e)));
    };
  }

  // Does not catch errors, only displays lefts
  displayErrorsAndRecoverMap<K, V>(label: string): OperatorFunction<Either<string, Map<K, V>>, Map<K, V>> {
    return (o: Observable<Either<string, Map<K, V>>>) => {
      return o.pipe(map(e => this.showErrorAndRecoverMap(label, e)));
    };
  }

  // Does not catch errors, only displays lefts
  displayErrorsAndRecoverSet<T>(label: string): OperatorFunction<Either<string, Set<T>>, Set<T>> {
    return (o: Observable<Either<string, Set<T>>>) => {
      return o.pipe(map(e => this.showErrorAndRecoverSet(label, e)));
    };
  }

  // Does not catch errors, only displays lefts
  displayErrorsAndRecoverString(label: string): OperatorFunction<Either<string, string>, string> {
    return (o: Observable<Either<string, string>>) => {
      return o.pipe(map(e => this.showErrorAndRecoverString(label, e)));
    };
  }

  showError(label: string, error: string): void {
    console.error(label, error);
    this.toasts.error(error, label);
  }

  showErrorAndRecoverEither<T>(label: string, e: Either<string, T>, fallback: T): T {
    if (e.isLeft()) {
      this.showError(label, e.value);
      return fallback;
    } else {
      return e.get();
    }
  }

  showErrorAndRecoverList<T>(label: string, e: Either<string, List<T>>): List<T> {
    return this.showErrorAndRecoverEither(label, e, List());
  }

  showErrorAndRecoverMap<K, V>(label: string, e: Either<string, Map<K, V>>): Map<K, V> {
    return this.showErrorAndRecoverEither(label, e, Map());
  }

  showErrorAndRecoverSet<T>(label: string, e: Either<string, Set<T>>): Set<T> {
    return this.showErrorAndRecoverEither(label, e, Set());
  }

  showErrorAndRecoverString<T>(label: string, e: Either<string, string>): string {
    return this.showErrorAndRecoverEither(label, e, '');
  }

  showSuccess(label: string, message: string): void {
    this.toasts.success(message, label);
  }

  showWarning(label: string, warning: string): void {
    console.warn(label, warning);
    this.toasts.warning(warning, label);
  }
}
