import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ComparisonUtils} from '@didgigo/lib-ts';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ApiConnectionService} from '../../services/api-connection.service';

@Component({
  selector: 'app-booking-message-dropdown',
  templateUrl: './booking-message-dropdown.component.html',
  styleUrls: ['./booking-message-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingMessageDropdownComponent implements OnInit {

  constructor(readonly apiConnection: ApiConnectionService) {
  }

  messagesFilter: BehaviorSubject<string> = new BehaviorSubject('');

  @ViewChild('messagesFilter', {static: false})
  messagesFilterElem: ElementRef;

  messagesToShow: Observable<List<string>>;

  @Input()
  reference: string;

  selected: ReplaySubject<string> = new ReplaySubject(1);

  private getMessagesToShow(): Observable<List<string>> {
    const agencies = this.apiConnection.getBookingMessageLabelsForSelectedAgency(this.reference);

    return combineLatest(agencies, this.messagesFilter)
      .pipe(map(([as, val]) => as.filter((d: string) => val === '' || d.includes(val))));
  }

  selectMessage(s: string): void {
    this.selected.next(s);
    this.messagesFilter.next('');
    this.messagesFilterElem.nativeElement.value = '';
  }

  updateMessageFilter(event): void {
    this.messagesFilter.next(event.target.value.toLowerCase());
  }

  ngOnInit(): void {
    this.messagesToShow = this.getMessagesToShow()
      .pipe(map(messages => messages.sort((a, b) => ComparisonUtils.stringComparator.compare(a, b))))
      .pipe(shareReplay(1));
  }
}
