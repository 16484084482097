import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserService} from '@didgigo/lib-angular';
import {ApiAgency, ApiConnection, ApiTemplate} from '@didgigo/lib-ts';
import {Option, Some} from 'funfix-core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiConnectionService, ConnectionState} from '../services/api-connection.service';

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.scss'],
})
export class MainPanelComponent implements OnInit {

  constructor(
    readonly apiConnection: ApiConnectionService,
    readonly user: UserService,
    readonly cdr: ChangeDetectorRef,
    readonly spinner: NgxSpinnerService) {
    this.spinner.show();
  }

  agencyLabeller = (c: ApiAgency) => c.getSummaryForLabel();

  agencyNameExtractor = (c: ApiAgency) => Some(c.getSummaryForLabel());
  connectionLabeller = (c: ApiConnection) => c.getLabel().getOrElse('Missing connection label');

  connectionNameExtractor = (c: ApiConnection) => c.getLabel();

  isProductImportOnly(state: ConnectionState): boolean {
    return state.api.exists(x => x.isProductImportOnly());
  }

  ngOnInit(): void {
  }

  option<T>(a: T | null | undefined): Option<T> {
    return Option.of(a);
  }

  templateLabeller = (c: ApiTemplate) => c.getShortCode().getOrElse('Missing agency label');

  templateNameExtractor = (c: ApiTemplate) => c.getShortCode();
}
