import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DidgigoApiService, UserService} from '@didgigo/lib-angular';
import {CollectionUtils, ComparisonUtils, OptionUtils, Proposal} from '@didgigo/lib-ts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Either, Option, Right} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment-timezone';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {BookingAnalyzerModalComponent} from '../booking-analyzer-modal/booking-analyzer-modal.component';
import {ProposalImportEndModalComponent} from '../proposal-import-end-modal/proposal-import-end-modal.component';
import {ApiConnectionService, ConnectionState} from '../services/api-connection.service';
import {ExcelCreatorService} from '../services/excel-creator.service';

class ListBookingRow {
  constructor(
    readonly reference: Option<string>,
    readonly title: Option<string>,
    readonly id: number | null,
    readonly qsid: Option<string>,
    readonly status: Option<string>,
    readonly createdDate: Moment | null,
    readonly travelDate: Moment | null,
    readonly presentAsCompany: Option<string>,
    readonly pdfLink: Option<string>,
  ) {
  }

  equals(other: ListBookingRow): boolean {
    return ComparisonUtils.optionStringComparator.compare(this.reference, other.reference) === 0
      && ComparisonUtils.optionNumberComparator.compare(Option.of(this.id), Option.of(other.id)) === 0;
  }

}

@Component({
  selector: 'app-list-bookings',
  templateUrl: './list-bookings.component.html',
  styleUrls: ['./list-bookings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBookingsComponent implements OnInit {

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly apiConnection: ApiConnectionService,
    readonly api: DidgigoApiService,
    readonly modalService: NgbModal,
    readonly user: UserService,
    readonly excel: ExcelCreatorService,
    readonly change: ChangeDetectorRef,
  ) {
    this.spinner.show();
  }

  bookings: BehaviorSubject<Either<string, List<Proposal>>> = new BehaviorSubject(Either.left('Not yet loaded'));

  loading = true;

  recents = true;

  rowFilter: BehaviorSubject<string> = new BehaviorSubject('');

  rows: BehaviorSubject<ListBookingRow[]> = new BehaviorSubject([]);

  @Input()
  state: ConnectionState;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  dateComparator(valueA: Moment | string, valueB: Moment | string): number {
    if (typeof valueA === 'string' || typeof valueB === 'string') {
      return -1;
    }
    return ComparisonUtils.dayComparator.compare(valueA, valueB);
  }

  private filterProposal(x: Proposal, val: string): boolean {
    return x.getReference().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getTitle().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getStatus().exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getPresentAsCompany().flatMap(s => s.getName()).exists(s => s.toLowerCase().includes(val.toLowerCase()))
      || x.getId().exists(s => s.toString().includes(val))
      || x.getStartDay().exists(s => s.format('MMM dd, YYYY').toLowerCase().includes(val.toLowerCase()))
      || x.getCreated().exists(s => s.format('MMM dd, YYYY').toLowerCase().includes(val.toLowerCase()));
  }

  private filterProposalList(proposals: List<Proposal>, val: string): List<ListBookingRow> {
    if (val === '') {
      return proposals.map(x => new ListBookingRow(
        x.reference,
        x.title,
        x.id.getOrElse(null),
        x.getQuickstartId(),
        OptionUtils.applyOrReturnNonEmpty(x.getApiStatus(), x.status, (a, b) => `${a}(${b})`),
        // @ts-ignore
        x.created.getOrElse(null),
        x.start.flatMap(s => s.time).getOrElse(null),
        x.presentAsCompany.flatMap(s => s.getName()),
        x.pdf.flatMap(s => s.link)));
    }

    return proposals
      .filter(x => this.filterProposal(x, val))
      .map(x => new ListBookingRow(
        x.reference,
        x.title,
        x.id.getOrElse(null),
        x.getQuickstartId(),
        OptionUtils.applyOrReturnNonEmpty(x.getApiStatus(), x.status, (a, b) => `${a}(${b})`),
        // @ts-ignore
        x.created.getOrElse(null),
        x.start.flatMap(s => s.time).getOrElse(null),
        x.presentAsCompany.flatMap(s => s.getName()),
        x.pdf.flatMap(s => s.link)));
  }

  // This was added as a quickfix for the security / GDPR / privacy issue that was reported relating to ePresentations in April 2023
  // It is not meant to be permanent
  getQsidForEpresentations(qsid: string): string {
    const proposalId = atob(qsid).substr(2);
    return btoa(`1234567890123456${proposalId}1234567890123456`).replace('=', '');
  }

  async import(ref: string): Promise<void> {
    this.spinner.show('import');
    const result = await this.apiConnection.importBookingForSelectedAgency(ref);
    this.spinner.hide('import');
    if (result.getId().nonEmpty()) {
      this.bookings.next(this.bookings.getValue().map(bs =>
        CollectionUtils.transform(
          bs,
          b => b.withId(result.getId()),
          b => b.isReference(ref))));
      this.openProposalModal(ref, result.getId().get());
    }
  }

  async loadBookings(): Promise<void> {
    console.log('Loading Bookings');
    this.loading = true;
    this.bookings.next(Right(List()));
    this.bookings.next(await this.apiConnection.listBookingsForSelectedAgency(this.state, this.recents));
    console.log('Finished Loading Bookings');
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    await this.loadBookings();
    this.setupRows();
  }

  openBookingAnalyzerModal(reference: string, proposalId: number): void {
    const modalRef = this.modalService.open(BookingAnalyzerModalComponent, {size: 'full'});
    modalRef.componentInstance.reference = reference;
    modalRef.componentInstance.proposalId = proposalId;
  }

  openProposalModal(reference: string, proposalId: number): void {
    const modalRef = this.modalService.open(ProposalImportEndModalComponent, {size: 'small'});
    modalRef.componentInstance.reference = reference;
    modalRef.componentInstance.proposalId = proposalId;
  }

  reset(): void {
    this.rowFilter.next('');
    this.loadBookings();
  }

  private setupRows(): void {
    combineLatest(this.bookings, this.rowFilter)
      .pipe(map(([proposals, val]) => this.filterProposalList(proposals.getOrElse(List()), val)))
      .subscribe(x => {
        this.rows.next(x.toArray());
        this.change.detectChanges();
      });
  }

  toggleRecent($event: any): void {
    this.recents = !this.recents;
    this.loadBookings();
  }

  updateFilter(event): void {
    this.rowFilter.next(event.target.value.toLowerCase().trim());
  }

}
