import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-api-connections-tab',
  templateUrl: './api-connections-tab.component.html',
  styleUrls: ['./api-connections-tab.component.scss'],
})
export class ApiConnectionsTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
