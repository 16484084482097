import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mapping-tab',
  templateUrl: './mapping-tab.component.html',
  styleUrls: ['./mapping-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MappingTabComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
