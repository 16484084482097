import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-analyzer-modal',
  templateUrl: './booking-analyzer-modal.component.html',
  styleUrls: ['./booking-analyzer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingAnalyzerModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
  ) {
  }

  @Input()
  reference: string;

  @Input()
  data: string;

  @Input()
  proposalId: number;

  ngOnInit() {
  }
}
